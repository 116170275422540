 <section id="contact" class="section section-contact scrollspy">
        <div class="container" >
                <h4 class="center">Matzikama Municipality Documents</h4>
            <div class="row">
                  <!--Start of 1st row-->
                <div class="col s12 m4 l4">
                    <div class="card #01579b light-blue darken-4 hoverable ">
                        <div class="card-content white-text">
                              <span class="card-title center-align truncate">Strategic Documents</span>
                              <p class="center-align truncate">View IDP, Budget, SDBIP, Annual Report & SDF.</p>
                        </div>
                        <div class="card-action center-align">
                            <a [routerLink]="['/stratdocs']" routerLinkActive="router-link-active" >View</a>
                        </div>
                    </div>
                </div>
                <div class="col s12 m4 l4">
                    <div class="card #01579b light-blue darken-4 hoverable">
                        <div class="card-content white-text">
                                  <span class="card-title center-align truncate">Tenders</span>
                                  <p class="center-align truncate">View all municipal tenders here.</p>
                        </div>
                        <div class="card-action center-align">
                                <a [routerLink]="['/tenders']" routerLinkActive="router-link-active" >View</a>
                        </div>
                    </div>

                </div>

                <div class="col s12 m4 l4">
                    <div class="card #01579b light-blue darken-4 hoverable">
                        <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Vacancies</span>
                                      <p class="center-align truncate">View all municipal vacancies here.</p>
                        </div>
                         <div class="card-action center-align">
                                    <a [routerLink]="['/vacancies']" routerLinkActive="router-link-active" >View</a>
                        </div>
                    </div>

                </div>

            </div>
            <!-- End of first row -->
            <div class="row"> <!--Start of 2nd row-->
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                  <span class="card-title center-align truncate">Financial Reports</span>
                                  <p class="center-align truncate">View all monthly financial reports(S71) here.</p>
                            </div>
                            <div class="card-action center-align">
                                <a [routerLink]="['/finance']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>

                    </div>
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Financial Policies</span>
                                      <p class="center-align truncate">View all financial policies here.</p>
                            </div>
                            <div class="card-action center-align ">
                                    <a [routerLink]="['/finpol']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>

                    </div>

                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                          <span class="card-title center-align truncate">Quarterly & Mid-Year Reports</span>
                                          <p class="center-align truncate">View all quarterly & midyear reports here.</p>
                            </div>
                             <div class="card-action center-align">
                                        <a [routerLink]="['/midyear']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>
                    </div>

            </div>
             <!-- End of second row -->
            <div class="row"> <!--Start of 3rd row-->
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                  <span class="card-title center-align truncate">Public Participation</span>
                                  <p class="center-align truncate">View public participation documents here.</p>
                            </div>
                            <div class="card-action center-align">
                                <a [routerLink]="['/publicparticipation']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>

                    </div>
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Investor Relations</span>
                                      <p class="center-align truncate">View investor relations documents here.</p>
                            </div>
                            <div class="card-action center-align">
                                    <a [routerLink]="['/investor']">View</a>
                            </div>
                        </div>

                    </div>
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                          <span class="card-title center-align truncate">Local Economic Development</span>
                                          <p class="center-align truncate">View LED documents here.</p>
                            </div>
                             <div class="card-action center-align">
                                        <a [routerLink]="['/led']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>

                    </div>

                </div>
                 <!-- End of third row -->
                 <div class="row">  <!--Start of 4th row-->
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Council</span>
                                      <p class="center-align truncate">View Council documents here.</p>
                                </div>
                                <div class="card-action center-align">
                                    <a [routerLink]="['/council']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                          <span class="card-title center-align truncate">Town Planning</span>
                                          <p class="center-align truncate">View Town Planning documents here.</p>
                                </div>
                                <div class="card-action center-align">
                                        <a [routerLink]="['/townplanning']">View</a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                              <span class="card-title center-align truncate">Building Control</span>
                                              <p class="center-align truncate">View building control documents here.</p>
                                </div>
                                 <div class="card-action center-align">
                                            <a [routerLink]="['/building-control']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of fourth row -->
                    <div class="row">   <!--Start of 5th row-->
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Valuations</span>
                                      <p class="center-align truncate">View valuation related documents here.</p>
                                </div>
                                <div class="card-action center-align">
                                    <a [routerLink]="['/valuation']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                          <span class="card-title center-align truncate">Borrowing Contracts</span>
                                          <p class="center-align truncate">View Town longterm loans here.</p>
                                </div>
                                <div class="card-action center-align">
                                        <a [routerLink]="['/contracts']">View</a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                              <span class="card-title center-align truncate">IDP Sector Plans</span>
                                              <p class="center-align truncate">View IDP Sector Plans here.</p>
                                </div>
                                 <div class="card-action center-align">
                                            <a [routerLink]="['/sectorPlans']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of 5th row -->
                     <div class="row">   <!--Start of 6th row-->
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Performance Management</span>
                                      <p class="center-align truncate">View performance documents here.</p>
                                </div>
                                <div class="card-action center-align">
                                    <a [routerLink]="['/performance']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                              <span class="card-title center-align truncate">Notices</span>
                                              <p class="center-align truncate">View municipal notices here.</p>
                                </div>
                                 <div class="card-action center-align">
                                            <a [routerLink]="['/municipal-notices']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text ">
                                              <span class="card-title center-align truncate">Media Statements</span>
                                              <p class="center-align truncate">View media statements here.</p>
                                </div>
                                 <div class="card-action center-align">
                                            <a [routerLink]="['/mediaStatements']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End of 6th row -->
                    <div class="row">   <!--Start of 7th row-->
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Ward Committees</span>
                                      <p class="center-align truncate">View Ward Committee information here.</p>
                                </div>
                                <div class="card-action center-align">
                                    <a [routerLink]="['/ward-committee']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>

                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text">
                                  <span class="card-title center-align truncate">Publications</span>
                                  <p class="center-align truncate">View publications here.</p>
                            </div>
                            <div class="card-action center-align">
                                <a [routerLink]="['/publications']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4 hoverable">
                            <div class="card-content white-text ">
                                  <span class="card-title center-align truncate">Emergency Loadshedding Relief Grant</span>
                                  <p class="center-align truncate">View loadshedding grant reports here.</p>
                            </div>
                            <div class="card-action center-align">
                                <a [routerLink]="['/grantLoadshedding']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>
                    </div>
                  </div>
                    <!-- End of 7th row -->
                    <!--Start of 7th row-->
                    <div class="row">   
                        <div class="col s12 m4 l4">
                            <div class="card #01579b light-blue darken-4 hoverable">
                                <div class="card-content white-text">
                                      <span class="card-title center-align truncate">Auctions</span>
                                      <p class="center-align truncate">View auctions here.</p>
                                </div>
                                <div class="card-action center-align">
                                    <a [routerLink]="['/auction']" routerLinkActive="router-link-active" >View</a>
                                </div>
                            </div>
                        </div>

                    <!-- <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4">
                            <div class="card-content white-text">
                                  <span class="card-title center-align">Publications</span>
                                  <p class="center-align">View publications here.</p>
                            </div>
                            <div class="card-action center-align">
                                <a [routerLink]="['/publications']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col s12 m4 l4">
                        <div class="card #01579b light-blue darken-4">
                            <div class="card-content white-text">
                                  <span class="card-title center-align">Emergency Loadshedding Relief Grant</span>
                                  <p class="center-align">View loadshedding grant reports here.</p>
                            </div>
                            <div class="card-action center-align">
                                <a [routerLink]="['/grantLoadshedding']" routerLinkActive="router-link-active" >View</a>
                            </div>
                        </div>
                    </div> -->
                  </div>
                  <!--End of 8th row-->
        </div>
      </section>
      <app-footer></app-footer>
