import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

  rietkuilUploadDate : Date = new Date('2020-01-21 09:04:22');
  bluedotUploadDate : Date = new Date('2020-01-22 11:36:14');
  erf2519UploadDate : Date = new Date('2020-01-29 16:42:02');
  erf1159UploadDate : Date = new Date('2020-03-03 15:26:48');
  erf267UploadDate : Date = new Date('2020-06-09 10:27:22');
  erf1320UploadDate : Date = new Date('2020-06-26 15:24:10');
  erf1299UploadDate : Date = new Date('2020-06-26 15:39:34');
  erf349UploadDate : Date = new Date('2020-08-24 18:20');
  Pls198_292UploadDate : Date = new Date('2020-09-09 13:48');
  k24UploadDate : Date = new Date('2020-05-20 10:51:22');
  IzalaFarmsUploadDate: Date = new Date('2020-09-18 16:53');
  BubeziUploadDate : Date = new Date('2020-09-18 17:01');
  Erf4748UploadDate:Date=new Date('2020-11-17 17:01');
  Erf4217UploadDate:Date=new Date('2020-11-30 14:08');
  Erf49UploadDate:Date=new Date('2021-01-26 11:35');
  lot564OrnUploadDate:Date=new Date('2021-02-26 10:49');
  Erf719UploadDate:Date=new Date('2021-02-26 11:03');
  Erf1539UploadDate:Date=new Date('2021-02-26 11:05');
  Erf46UploadDate:Date=new Date('2021-04-14 10:02');
  Erf2706UploadDate:Date=new Date('2021-07-15 12:50');
  Erf536UploadDate:Date=new Date('2021-08-12 09:09');
  Erf333UploadDate:Date=new Date('2021-08-18 09:30');
  Erf132UploadDate:Date=new Date('2021-08-28 11:43');
  DeptPublicWorksUploadDate:Date=new Date('2021-10-20 14:52');
  Erf789UploadDate:Date=new Date('2021-10-20 14:55');
  Erf230UploadDate:Date=new Date('2021-11-29 13:41');
  Erf1071VdalUploadDate:Date=new Date('2021-12-01 09:31');
  Erf31LvilleUploadDate:Date=new Date('2022-03-16 16:12');
  Erf702LRUploadDate:Date=new Date('2022-05-05 08:39');
  Erf3040UploadDate:Date=new Date('2022-05-16 08:50');
  Ptn530f47UploadDate:Date=new Date('2022-05-16 09:01');
  Pl30699UploadDate:Date=new Date('2022-05-27 09:36');
  Erf52UploadDate:Date=new Date('2022-05-27 09:41');
  Erf535UploadDate:Date=new Date('2022-07-14 09:08');
  Erf160UploadDate:Date=new Date('2022-07-14 09:12');
  Erf66UploadDate:Date=new Date('2022-07-29 09:34');
  Erf475LouisRoodUploadDate:Date=new Date('2022-08-03 14:16');
  Erf387LouisRoodUploadDate:Date=new Date('2022-08-03 14:29');
  Erf770VredendalUploadDate:Date=new Date('2022-08-28 19:11');
  Plot47ORNUploadDate:Date=new Date('2022-08-28 19:13');
  RioTintoProspectingUploadDate:Date=new Date('2022-09-01 16:05');
  Erf1577VredendalUploadDate:Date=new Date('2022-09-08 16:32');
  Erf1229KlawerUploadDate:Date=new Date('2022-10-24 09:25');
  Farm270UploadDate:Date=new Date('2022-12-02 10:31');
  Erf656UploadDate:Date=new Date('2022-12-13 10:43');
  Erf477UploadDate:Date=new Date('2023-02-20 11:25');
  RestantErf31UploadDate:Date=new Date('2023-02-20 11:53');
  Erf3292UploadDate:Date=new Date('2023-02-21 08:51');
  Erf698UploadDate:Date=new Date('2023-03-03 08:17');
  Pls292UploadDate:Date=new Date('2023-04-14 08:56');
  Erf696UploadDate:Date=new Date('2023-05-05 09:27');
  Erf4207UploadDate:Date=new Date('2023-05-12 10:20');
  Erf3976UploadDate:Date=new Date('2023-05-19 12:21');
  Erf4944UploadDate:Date=new Date('2023-06-13 12:13'); 
  Erf77UploadDate:Date=new Date('2023-06-13 12:16'); 
  Erf177UploadDate:Date=new Date('2023-07-13 15:56'); 
  Pls292237UploadDate:Date=new Date('2023-07-20 15:51'); 
  Pls29512UploadDate:Date=new Date('2023-08-07 08:40'); 
  WcdmBitterUploadDate:Date=new Date('2023-08-18 08:59'); 
  Erf334292UploadDate:Date=new Date('2023-09-14 11:14');
  Pls292426UploadDate:Date=new Date('2023-09-19 11:46'); 
  Erf230LouisRood:Date=new Date('2023-10-13 14:27'); 
  Erf334LouisRood:Date=new Date('2023-11-01 21:06'); 
  Pls384298UploadDate:Date=new Date('2023-11-14 11:47'); 
  Erf933UploadDate:Date=new Date('2023-11-16 10:18'); 
  Erf32344475UploadDate:Date=new Date('2023-11-16 10:21'); 
  Erf550UploadDate:Date=new Date('2023-12-07 14:26'); 
  Erf1128UploadDate:Date=new Date('2023-12-07 14:28'); 
  Pls149_4UploadDate:Date=new Date('2023-12-12 11:41'); 
  Erf691LouisRoodUploadDate:Date=new Date('2024-01-24 13:34'); 
  Erf682LutzvilleUploadDate:Date=new Date('2024-01-24 13:36'); 
  Pls292_354VredendalUploadDate:Date=new Date('2024-02-02 08:19'); 
  Erf479VredendalUploadDate:Date=new Date('2024-02-06 20:11'); 
  Pls308UploadDate:Date=new Date('2024-02-23 12:56'); 
  Pls257UploadDate:Date=new Date('2024-02-26 13:03'); 
  Erf810UploadDate:Date=new Date('2024-02-29 11:22'); 
  Erf1115UploadDate:Date=new Date('2024-03-13 16:26'); 
  Plot591UploadDate:Date=new Date('2024-03-13 16:29'); 
  Erf48UploadDate:Date=new Date('2024-03-22 11:16'); 
  Pls258_7:Date=new Date('2024-04-03 15:43'); 
  Erf549:Date=new Date('2024-04-15 09:22'); 
  Erf53:Date=new Date('2024-04-26 14:22'); 
  Erf1952:Date=new Date('2024-06-10 10:24'); 
  Erf296:Date=new Date('2024-06-14 09:00'); 
  Erf108Lutzville:Date=new Date('2024-06-20 15:31'); 
  Erf2515Vredendal:Date=new Date('2024-07-11 10:20');
  Erf1622Klawer:Date=new Date('2024-07-29 21:38'); 
  Erf316319320Vredendal:Date=new Date('2024-08-14 15:47'); 
  Erf44Lutzville:Date=new Date('2024-08-26 08:36'); 






























  constructor() { }

  ngOnInit() {
  }

}
