import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-council',
  templateUrl: './council.component.html',
  styleUrls: ['./council.component.css']
})
export class CouncilComponent implements OnInit {

  MayoralComm25May2020 : Date = new Date ('2020-05-22 15:12:26');
  CouncilMinutes1920 : Date = new Date ('2020-07-27 11:02:13');
  CouncilMinutes2021 : Date = new Date ('2021-07-01 16:36');
  CouncilMeeting28072020 : Date = new Date ('2020-07-27 11:15:36');
  minutes202021:Date = new Date('2021-11-02 10:08');
  February2022:Date = new Date('2022-04-12 10:13');
  January2022:Date = new Date('2022-04-12 10:18');
  December2022:Date = new Date('2021-04-12 11:39');
  March2022:Date = new Date('2022-07-28 16:18');
  April2022:Date = new Date('2022-07-28 16:25');
  May2022:Date = new Date('2022-11-02 12:21');
  June2022:Date = new Date('2022-11-02 12:28');
  July2022:Date = new Date('2022-11-02 12:44');
  August2022:Date = new Date('2022-11-02 12:47');
  CouncilMinutes2223:Date=new Date('2023-08-07 08:57'); 
  july252324:Date=new Date('2023-09-13 15:50');
  july262324:Date=new Date('2023-09-13 15:52');
  August2023:Date=new Date('2023-11-09 16:23');
  Oct2023:Date=new Date('2024-02-20 12:12');
  Dec2023:Date=new Date('2024-02-20 12:15');
  Jan2024:Date=new Date('2024-03-04 08:20');
  Feb2024:Date=new Date('2024-04-11 10:15');
  March2024:Date=new Date('2024-07-02 08:56');
  April2024:Date=new Date('2024-07-02 08:58');
  May2024:Date=new Date('2024-07-02 09:03');



















  constructor() { }

  ngOnInit() {
  }

}
